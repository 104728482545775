
<template>
    <div class="container">
        <div class="title flex_center_between_box">
            <div class="title_txt flex_center">
                <img src="../../../assets/img/company/Ownership11.png" />
                Ownership
            </div>
            <div class="title_txt-right ">
                <div class="tbl_search_box">
                    <div class="tbl_search">
                        <el-input v-model="searchTblName" type="text" clearable placeholder="Please enter name" @change="searchPersonByName()" />
                        <img src="../../../assets/img/detail/search_ic1.png" @click="searchPersonByName" />
                    </div>
                </div>
                <download-excel class="export-excel-wrapper computer_right_cont_box" :data="DetailsForm" :fields="json_fields" :header="title" name="Ownership.xls">
                    <div class="btn_export flex_center" @click="exportCompany">
                        <img src="../../../assets/img/search/export_ic1.png" />
                        Export
                    </div>
                </download-excel>
            </div>
        </div>

        <div class="table">
            <div class="right_cont_box">
                <!-- pc端 -->
                <div class="table_box ">

                    <el-table ref="treeTable" class="computer_right_cont_box" :data="parentTablelist" border style="width: 100%">
                       
                        <template slot="empty">
                            <noDate />
                        </template>
                        <el-table-column label="Name" align="left" width="400" show-overflow-tooltip sortable :sort-method="sortName" :resizable="false" class-name="company company_link">
                            <template slot-scope="scope">
                          
                                <span class="tbl_company" v-show="!showTranslate" @click="setid(scope.row)">{{ scope.row.name }}</span>
                                <span class="tbl_company" v-show="showTranslate" @click="setid(scope.row)">{{ scope.row.nameCn }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column v-for="(column, index) in filteredColumns" :key="index" :label="column.label" :prop="column.prop" :width="column.width" :sortable="column.sortable"  :resizable="column.resizable" :show-overflow-tooltip="column.showOverflowTooltip" :class-name="column.className">
                            <template slot-scope="scope">
                                <span @click="handleClick(scope.row, column)">{{ scope.row[column.prop] }}</span>
                            </template>

                            <template slot="header" slot-scope="scope">
                                <div class="column-handle" draggable="true" @dragstart="onDragStart(scope, index)" @dragover="onDragOver(scope, index)" @dragend="onDragEnd">
                                    <span>{{ column.label }}</span>
                                </div>
                            </template>
                        </el-table-column>
                     
                    </el-table>
                    <!-- 移动端 -->
                <div class=" phone_page_bg">
                    <div class="table_box">
                          <ul class="phone_table_box">
                        <li class="phone_table_box_item" v-for="(item,index) in parentTablelist" :key="index">
                         <div class="phone_table_box_item_right ">
                           <h1 class=" line_1" @click="setid(item)"  >
                                  {{ item.name }}
                          </h1>
                         <h2 class="line_1" > Type: {{item.type}}</h2>
                           <h2 class="line_1"><span >Country:{{item.country}}</span></h2>
                           <h2 class="line_1"><span >Relations:{{item.relationshipEn}}</span> </h2>
                           <h2 class="line_1"><span >Shares(%):{{item.shares}}</span></h2>
                         </div>
                        </li>
                    </ul>
                    </div>
                </div>
                    <div class="page_i_box">
                        <Page :totalFont="true" :total="page.total" @onPageChange="onPageChangeList" @currtentPageChange="currtentPageChange"></Page>
                        <div class="search_rsult_txt">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results</div>
                    </div>
                </div>
             

            </div>
        </div>
        <div class="footer computer_right_cont_box" v-loading="loading">
            <div class="ecahrts-header">
                <span class="title-left">
                    <img src="../../../assets/img/company/echarts.png" alt="" style="margin-right: 8px" />
                    Charts
                </span>

                <div class="head_loging" :class="loginStatus">
                    <el-switch v-model="loginStatus" @change="loginPop" active-color="#13ce66"></el-switch>
                </div>
            </div>
            <div v-show="loginStatus" class="ecahrtstu">
                <div ref="trendChart" class="trendEchartBox1" />
                <div class="echartsBtns">
                    <el-button icon="el-icon-plus" :disabled="level >= 3" @click="changeLevel('plus')" />
                    <el-button icon="el-icon-minus" :disabled="level <= 1" @click="changeLevel('minus')" />
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="showColumnSelector" center :close-on-click-modal="false" custom-class="dialog_normalsuo" :append-to-body="true">
  <el-checkbox-group v-model="selectedColumns">
    <el-checkbox v-for="(column,index) in columns" :key="index" :label="column.prop">{{ column.label }}</el-checkbox>
  </el-checkbox-group>
  <button @click="handleColumnSelection">Ok</button>
</el-dialog>
    </div>
</template>
<script>
import { getOwnership, getCompanyChart, getCompanyCharttwo } from '../../../api/companyApi';
import crypto from '@/utils/crypto';
import noDate from '@/components/tableNoDate';
import Page from '@/components/page';
// import { data } from './data';
import { mixins1 } from '@/mixins/index';
import { formatterTxt, formatterTxtTips, formatList } from './utils';
import { data } from '../../User/data';
// import draggable from 'vuedraggable';
export default {
    mixins: [mixins1],
    components: { noDate, Page },
    data() {
        return {
            aaid: '',
            level: 1,
            showColumnSelector:false,
            echartsdate: [],
            loginStatus: false, //登录状态
            columns: [
                // { label: 'Name', prop: 'name', width: '400', sortable: true, resizable: false, showOverflowTooltip: true, className: 'company company_link' ,visible:true},
                { label: 'Type', prop: 'type', width: '258', resizable: false ,visible:true},
                { label: 'Country', prop: 'country', width: '200', resizable: false, className: 'company_link' ,visible:true},
                { label: 'Shares(%)', prop: 'shares', width: '140', align: 'center', sortable: true, resizable: false,visible:true },
                { label: 'Relations', prop: 'relationshipEn', width: '150', align: 'right', resizable: false ,visible:true},
            ],
          
            selectedColumns:[],
            loading: true,
            columnOrder: [],
            // companyAaaid: '156107587326',
            comIcon1: 'image://' + require('@/assets/img/echarts/comIcon1.png'),
            comIcon2: 'image://' + require('@/assets/img/echarts/WechatIMG31.png'),
            comIcon3: 'image://' + require('@/assets/img/echarts/comIcon3.png'),
            comIcon4: 'image://' + require('@/assets/img/echarts/comIcon4.png'),
            comIcon5: 'image://' + require('@/assets/img/echarts/comIcon5.png'),
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            icon: '',
            searchTblName: '',
            parentTablelist: [],
            parentTable1: [],
            tableList: [],
            // 下载
            title: 'Ownership',
            json_fields: {
                Name: 'name',
                Type: 'type',
                Country: 'country',
                'Shares(%)': 'shares',
                Relations: 'relationshipEn',
            },
            DetailsForm: [],
            companyAaaid: this.$route.query.id3a,
            // echarts
            tempId: '',
            tempObject: {},
            chartData: null,
            level1: 1,
        };
    },
    // computed: {
    //   openMenu: {
    //     get() {
    //       return this.menuTitleList.map((i) => {
    //         return i.title
    //       })
    //     },
    //     set() {},
    //   },
    // },
    computed: {
        filteredColumns() {
    return this.columns.filter(column => column.visible);
  },
        // index传值 是否翻译
        openMenu: {
            get() {
                return this.menuTitleList.map(i => {
                    return i.title;
                });
            },
            set() {},
        },
        showTranslate: {
            get() {
                return this.$parent.indexIsTranslate;
            },
            set() {},
        },
        curPagePower: {
            get() {
                return JSON.parse(this.$parent.powerToPage);
            },
            set() {},
        },
    },
    watch: {},
    mounted() {
        this.init();
        // this.columnOrder = this.$refs.treeTable.columns.map(column => column.property);
        this.echartslist();
        // this.$echarts.init(this.$refs.trendChart).on('click', this.handleChartClick);
    },

    // guoen版本
    methods: {
        handleColumnSelection() {
    // 更新columns中各列的visible属性
   let that =this
    this.$nextTick(()=>{
        that.columns.forEach(column => {
      column.visible = that.selectedColumns.includes(column.prop);
    });
    })
console.log(`his.columns`, this.columns);
    this.showColumnSelector = false; // 关闭列选择器
  },
        handleClick(row, com) {
            if (com.label == 'Country') {
                this.gotocountry1(row);
            }
            if (com.label == 'Name') {
                this.setid(row);
            }
            console.log(row);
            console.log(com);
        },
        onDragStart(scope, index) {
            this.draggingIndex = index;
        },
        onDragOver(scope, index) {
            if (this.draggingIndex !== null) {
                const draggedColumn = this.columns[this.draggingIndex];
                this.columns.splice(this.draggingIndex, 1);
                this.columns.splice(index, 0, draggedColumn);
                this.draggingIndex = index;
            }
        },
        onDragEnd() {
            this.draggingIndex = null;
        },
        async init() {
            const params = {
                // id3a: JSON.parse(sessionStorage.getItem('company')).aaa_id,
                // companyCountry: JSON.parse(sessionStorage.getItem('company')).company_country,
                id3a: this.$route.query.id3a,
                companyCountry: this.$route.query.companyCountry,
            };
            const res = await getOwnership(params);

            let date = JSON.parse(crypto.decrypt(res));
            if (date && date.code) {
                console.log(date.data, 'parentTable1');
                this.parentTable1 = date.data;
                this.tableList = date.data;
                this.pageList();
            }
        },
        setid(row) {
            // this.$emit('updateSubLink', 0);
            row.company_name_en = row.name;
            // console.log(`row`, row);
            sessionStorage.setItem('company', JSON.stringify(row));
            if (row.aaaId != ' ') {
                let routeData = this.$router.resolve({
                    path: '/company/companysnapshot?id3a=' + row.aaaId + '&companyCountry=' + row.country,
                });
                window.open(routeData.href, '_blank');
            }
        },
        //导出
        exportCompany() {
            console.log('ownership- curPagePower--', this.curPagePower);
            if (this.curPagePower[0].power) {
                this.DetailsForm = this.parentTable1;
            } else {
                this.message();
            }
        },

        sortName(a, b) {
            return a.name.localeCompare(b.name);
        },
        // 排序
        sortScore(a, b) {
            return a.shares - b.shares;
        },
        // 人名筛选
        searchPersonByName() {
            let list = this.tableList;
            let keyWord = this.searchTblName;
            var arr = [];
            if (keyWord == '') {
                this.parentTablelist = list;
                this.parentTable1 = list;
            } else {
                // console.log(`list`, list);
                console.log(keyWord);
                arr = list.filter(item => {
                    return item.name.toUpperCase().indexOf(keyWord.toUpperCase()) !== -1;
                });
                console.log(arr);
                this.parentTable1 = arr;
            }
            this.pageList();
        },
        // 分页
        pageList() {
            // console.log(this.parentTable);

            this.parentTablelist = this.parentTable1.filter((item, index) => index < this.page.pageNo * this.page.pageSize && index >= this.page.pageSize * (this.page.pageNo - 1));
            this.page.total = this.parentTable1.length;
        },
        onPageChangeList(pageNo) {
            // this.pageNo += 1
            this.page.pageNo = pageNo;
            this.pageList();
        },
        currtentPageChange(pageSize) {
            // this.pageNo += 1
            this.page.pageSize = pageSize;
            // this.init()
            this.pageList();
        },
        // 国家跳转
        gotocountry1(row) {
            if (row.countryCode != 'n.a.') {
                let routeData = this.$router.resolve({
                    path: '/country?' + '&companyCountry=' + row.countryCode,
                });

                window.open(routeData.href, '_blank');
            }
            //  if(!row.countryCode.includes(';')){

            //  }
        },
        getCellStyle({ column }) {
            //TODO 针对Safari表格width与showOverflowTooltip暂不能共存异常
            const tempWidth = column.realWidth || column.width;
            if (column.showOVerflowTooltip) {
                return {
                    minWidth: tempWidth + 'px',
                    maxWidth: tempWidth + 'px',
                };
            }
            return {};
        },
        async handleChartClick(node) {
            const params = {
                id3a: node.data.code,
                companyCountry: this.$route.query.companyCountry,
                charType: 1,
            };
            if (!this.curPagePower[1].power) {
                this.$parent.getOrdersAdd(19).then(res => {
                    if (!res) {
                        console.log(`11`, 11);
                        this.level = 0;
                        // this.$nextTick(() => {
                        //     this.echarts();
                        // });
                    } else {
                        getCompanyCharttwo(params).then(res => {
                            let ecahrtsdata = JSON.parse(crypto.decrypt(res)) || {};
                            ecahrtsdata = JSON.parse(JSON.stringify(ecahrtsdata).replace(/subsetList/g, 'children'));
                            if (ecahrtsdata && ecahrtsdata.code) {
                                console.log(`ecahrtsdata.data.children.length`, ecahrtsdata.data.children.length);
                                if (ecahrtsdata.data.children.length > 0) {
                                    // this.level++;
                                    this.loading = false;
                                    // let echartds1=this.echartsdate.children
                                    this.getList(this.echartsdate.children, node.data.code, ecahrtsdata.data.children);
                                    this.setEchartList();
                                    this.$nextTick(() => this.echarts());
                                }
                            } else {
                                this.loading = false;
                            }
                        });
                    }
                });
            } else {
                const res = await getCompanyCharttwo(params);
                let ecahrtsdata = JSON.parse(crypto.decrypt(res)) || {};
                ecahrtsdata = JSON.parse(JSON.stringify(ecahrtsdata).replace(/subsetList/g, 'children'));
                if (ecahrtsdata && ecahrtsdata.code) {
                    console.log(`ecahrtsdata`, ecahrtsdata);
                    if (ecahrtsdata.data.children.length > 0) {
                        this.level++;
                        this.loading = false;
                        this.getList(this.echartsdate.children, node.data.code, ecahrtsdata.data.children);
                        // this.setEchartList();
                        this.$nextTick(() => this.echarts());
                    }
                } else {
                    this.loading = false;
                }
            }
        },
        // 递归获取数组
        getList(list, code, newVal) {
            list.forEach((item, index) => {
                if (list[index].code === code) {
                    list[index].children = newVal;
                } else if (list[index].children && list[index].children.length > 0) {
                    this.getList(list[index].children, code, newVal);
                }
            });
        },
        handleChildren(node) {
            if (node.children) {
                node.children.forEach(child => {
                    const params = {
                        nodeId: child.id,
                    };
                    this.getCompanyCharttwo(params).then(children => {
                        if (children && children.length > 0) {
                            child.children = children;
                            this.chart.setOption(this.option);
                            this.handleChildren(child);
                        }
                    });
                });
            }
        },
        getCompanyCharttwo(params) {
            // 根据节点参数请求接口获取子节点数据
            return new Promise(resolve => {
                // 发送请求并获取子节点数据
                // resolve(children)
            });
        },

        async echartslist() {
            const params = {
                id3a: this.$route.query.id3a,
                companyCountry: this.$route.query.companyCountry,
                charType: 1,
            };
            const res = await getCompanyCharttwo(params);
            const ecahrtsdata = JSON.parse(crypto.decrypt(res));
            if (ecahrtsdata && ecahrtsdata.code) {
                this.loading = false;
                this.echartsdate = ecahrtsdata.data;
                this.setEchartList();
                this.$nextTick(() => this.echarts());
            } else {
                this.loading = false;
            }
        },
        setEchartList(echartsdate = this.echartsdate) {
            const echartData = echartsdate.subsetList || []; // 股东list
            // this.nodesBean = basicInfo.enterprise // 目标公司
            // console.log(basicInfo);
            // console.log(echartData);
            // console.log(this.nodesBean);
            const data = {
                name: this.echartsdate.name,
                nameEn: this.echartsdate.nameEn,
                code: this.companyAaaid,
                children: [],
            };
            const childrenList = formatList(echartData, this.companyAaaid, this.companyAaaid);
            data.children = childrenList;
            if (childrenList && childrenList.length > 0) {
                this.getLevel2Children(data, this.companyAaaid);
            } else {
                this.echartData = data;
            }
        },
        getLevel2Children(vals = data, aaaid) {
            // console.log(`vals`, vals);
            const echartData = vals;
            console.log(``, echartData);

            echartData.children.map((item, index) => {
                if (item.isMax == aaaid) {
                    const echartData = item.subsetList || [];
                    const subsetList = formatList(echartData, this.companyAaaid, this.companyAaaid);
                    item.subsetList = subsetList;
                }
            });
            this.echartsdate = echartData;
        },

        add(list) {
            console.log(`list`, list);
            list.forEach(async item => {
                // console.log(`item`, item);
                if (!item.children.length) {
                    if (item.code) {
                        const params = {
                            id3a: item.code,
                            companyCountry: this.$route.query.companyCountry,
                            charType: 1,
                        };
                        console.log(this.level);
                        const res = await getCompanyCharttwo(params);
                        let ecahrtsdata = res && JSON.parse(crypto.decrypt(res));
                        ecahrtsdata = JSON.parse(JSON.stringify(ecahrtsdata).replace(/subsetList/g, 'children'));
                        if (ecahrtsdata && ecahrtsdata.code) {
                            this.loading = false;
                            console.log(ecahrtsdata);
                            this.getList(this.echartsdate.children, item.code, ecahrtsdata.data.children);
                            console.log(`this.echarts`, this.echartsdate);
                            this.setEchartList();
                            this.$nextTick(() => this.echarts());
                        } else {
                            this.loading = false;
                        }
                    }
                } else {
                    this.add(item.children);
                }
            });
        },
        changeLevel(type) {
            // console.log('层级', this.level);
            if (!this.curPagePower[1].power) {
                this.$parent.getOrdersAdd(19).then(res => {
                    if (!res) {
                        console.log(`11`, 11);
                        this.level = 0;
                        // this.$nextTick(() => {
                        //     this.echarts();
                        // });
                    } else {
                        if (type === 'plus') {
                            this.level++;
                            let that = this;
                            // 点加号按钮寻找当前数组的最外层children
                            if (this.level >= 2) {
                                if (this.level1 < 4) {
                                    that.add(that.echartsdate.children);
                                }
                            }
                            console.log(this.echartsdate);
                            // this.setEchartList();
                        }
                    }
                });
            } else {
                if (type === 'plus') {
                    this.level++;
                    this.level1++;
                    this.level1 > 4 ? (this.level1 = 4) : this.level11;
                    console.log(`111`, 111);
                    console.log(``, this.level);
                    // 点加号按钮寻找当前数组的最外层children
                    let that = this;
                    if (this.level >= 2) {
                        console.log(``, this.level1);
                        if (this.level1 < 4) {
                            that.add(that.echartsdate.children);
                        }
                        // that.echartsdate.children.map((item) => {
                        // console.log(`item`, item);

                        // console.log(that.add());

                        // });
                    }

                    // this.setEchartList();
                }
            }

            if (type === 'minus') this.level--;
            this.$nextTick(() => this.echarts());
        },

        echarts() {
            const that = this;
            let leval = that.level;
            const initialTreeDepth = leval;
            that.echartsdate = JSON.parse(JSON.stringify(that.echartsdate).replace(/subsetList/g, 'children'));
            console.log(that.echartsdate);
            //  that.echartsdate.map((item,index)=>{

            //  })
            let data = that.echartsdate;
            // console.log(`dataa`, data);
            const myChart = this.$echarts.init(this.$refs.trendChart);
            myChart.showLoading();

            myChart.hideLoading();

            const symbol = data => {
                // console.log(`data`, data.code);
                if (`${data.invtypecode}` === '3') {
                    // 外国公司
                    return this.comIcon3;
                }
                if (`${data.invtypecode}` === '5') {
                    // 国营企业
                    return this.comIcon5;
                }

                if (`${data.code}` === this.$route.query.id3a) {
                    return this.comIcon4;
                }
                if (data.type == '2') {
                    // 个人
                    return this.comIcon2;
                }
                return this.comIcon1;
            };
            // console.log(`symbol`,symbol );
            const option = {
                tooltip: {
                    trigger: 'item',
                    triggerOn: 'mousemove',
                    fontSize: 12,
                    formatter: params => formatterTxtTips(params),
                },
                series: [
                    {
                        type: 'tree',
                        data: [data],
                        top: '18%',
                        bottom: '14%',
                        // roam: true,
                        clickable: true,

                        layout: 'radial',
                        symbol: (value, param) => symbol(param.data),
                        symbolSize: 30,
                        initialTreeDepth,
                        animationDurationUpdate: 750,
                        emphasis: {
                            focus: 'ancestor',
                        },
                        orient: 'TB',
                        label: {
                            fontSize: 9,
                            formatter: params => formatterTxt(params),
                            rotate: 360,
                        },
                        leaves: {
                            label: {
                                fontSize: 9,
                                formatter: params => formatterTxt(params),
                                rotate: 360,
                            },
                        },
                    },
                ],
            };
            myChart.setOption(option);

            // myChart.resize()
        },
        handleChange(val) {
            this.icon = val;
            // this.iconlist=val
            // this.iconlist.some(item=>{
            //     console.log(item);
            // })
        },
        loginPop() {
            this.echartsdate = JSON.parse(JSON.stringify(this.echartsdate).replace(/subsetList/g, 'children'));
            // this.add(this.echartsdate.children);
            this.echartStatus = true;
            this.echarts();

            // if (this.curPagePower[1].power) {

            // } else {
            //     this.$parent.getOrdersAdd(19).then(res => {
            //         if (res) {
            //             this.echarts();
            //         }
            //     });
            // }
        },
    },
    // echarts慢版本
    //     methods: {
    //     handleChartClick(params) {

    //         if (!this.curPagePower[1].power) {
    //             this.$parent.getOrdersAdd(19).then(res=>{
    //                if(!res){

    //                 this.level=1
    //                 // console.log(params);
    //                 // params.event.stop()
    //                 // params.data.children =[]

    //                 // console.log(params.data.children);
    //                 // // this.parentTable1.map(item=>{
    //                 // //     console.log(item);
    //                 // // })
    //                 // this.echartsdate.children.map(item=>{
    //                 //     item.children=[]
    //                 // })
    //                 // console.log(this.echartsdate);
    //                 this.$nextTick(() => {
    //             this.echarts();
    //         });
    //                }
    //             })
    // }

    //     //   const node = params.data
    //     //   console.log(`node.children`, node.children);
    //     //   if (node.children.length == 0) {
    //     //     // 如果点击的是二级节点，则获取节点参数，并请求接口获取子节点数据
    //     //     const params = {
    //     //         id3a: node.code,
    //     //             // id3a: JSON.parse(sessionStorage.getItem('company')).aaa_id,
    //     //             // companyCountry: JSON.parse(sessionStorage.getItem('company')).company_country,
    //     //             companyCountry: this.$route.query.companyCountry,
    //     //             charType: 1,
    //     //     }
    //     //    getCompanyCharttwo(params).then(children => {

    //     //     children=JSON.parse(crypto.decrypt(children))
    //     //     console.log(`children`, children);
    //     //       if (children && children.subsetList.length > 0) {
    //     //         // 如果子节点数据不为空，则将子节点数据插入到当前节点的 children 数组中，并递归获取子节点的数据
    //     //         node.children = children
    //     //         // this.chart.setOption(this.option)
    //     //         this.handleChildren(node)
    //     //       } else {
    //     //         // 如果子节点数据为空，则将接口返回的数据插入到当前节点的 children 数组中
    //     //         node.children = children
    //     //         this.chart.setOption(this.option)
    //     //       }
    //     //     })
    //     //   }
    //     },
    //     handleChildren(node) {
    //       if (node.children) {
    //         node.children.forEach(child => {
    //           const params = {
    //             nodeId: child.id
    //           }
    //           this.getCompanyCharttwo(params).then(children => {
    //             if (children && children.length > 0) {
    //               child.children = children
    //               this.chart.setOption(this.option)
    //               this.handleChildren(child)
    //             }
    //           })
    //         })
    //       }
    //     },
    //     getCompanyCharttwo(params) {
    //       // 根据节点参数请求接口获取子节点数据
    //       return new Promise(resolve => {
    //         // 发送请求并获取子节点数据
    //         // resolve(children)
    //       })
    //     },

    //         sortName(a, b) {
    //   return a.name.localeCompare(b.name)
    // },
    //         // 排序
    //         sortScore(a, b) {
    //   return a.shares - b.shares
    // },
    //         // 人名筛选
    //         searchPersonByName() {
    //             let list = this.tableList;
    //             let keyWord = this.searchTblName;
    //             var arr = [];
    //             if (keyWord == '') {
    //                 this.parentTablelist = list;
    //                 this.parentTable1=list
    //             } else {
    //                 // console.log(`list`, list);
    //                 console.log(keyWord);
    //                 arr = list.filter(item => {
    //                     return item.name.toUpperCase().indexOf(keyWord.toUpperCase()) !== -1;
    //                 });
    //                 console.log(arr);
    //                 this.parentTable1 = arr;
    //             }
    //             this.pageList();
    //         },
    //         // 分页
    //         pageList() {
    //             // console.log(this.parentTable);

    //             this.parentTablelist = this.parentTable1.filter((item, index) => index < this.page.pageNo * this.page.pageSize && index >= this.page.pageSize * (this.page.pageNo - 1));
    //             this.page.total = this.parentTable1.length;
    //         },
    //         onPageChangeList(pageNo) {
    //             // this.pageNo += 1
    //             this.page.pageNo = pageNo;
    //             this.pageList();
    //         },
    //         currtentPageChange(pageSize) {
    //             // this.pageNo += 1
    //             this.page.pageSize = pageSize;
    //             // this.init()
    //             this.pageList();
    //         },
    //         // 国家跳转
    //         gotocountry1(row) {
    //             if (row.countryCode != 'n.a.') {
    //                 let routeData = this.$router.resolve({
    //                     path: '/country?' + '&companyCountry=' + row.countryCode,
    //                 });

    //                 window.open(routeData.href, '_blank');
    //             }
    //             //  if(!row.countryCode.includes(';')){

    //             //  }
    //         },
    //         getCellStyle({ column }) {
    //             //TODO 针对Safari表格width与showOverflowTooltip暂不能共存异常
    //             const tempWidth = column.realWidth || column.width;
    //             if (column.showOVerflowTooltip) {
    //                 return {
    //                     minWidth: tempWidth + 'px',
    //                     maxWidth: tempWidth + 'px',
    //                 };
    //             }
    //             return {};
    //         },

    //         async init() {
    //             const params = {
    //                 // id3a: JSON.parse(sessionStorage.getItem('company')).aaa_id,
    //                 // companyCountry: JSON.parse(sessionStorage.getItem('company')).company_country,
    //                 id3a: this.$route.query.id3a,
    //                 companyCountry: this.$route.query.companyCountry,
    //             };
    //             const res = await getOwnership(params);

    //             let date = JSON.parse(crypto.decrypt(res));
    //             if (date && date.code) {
    //                 console.log(date.data, 'parentTable1');
    //                 this.parentTable1 = date.data;
    //                 this.tableList = date.data
    //                 this.pageList();
    //             }
    //         },
    //         setid(row) {
    //             // this.$emit('updateSubLink', 0);
    //             row.company_name_en = row.name;
    //             // console.log(`row`, row);
    //             sessionStorage.setItem('company', JSON.stringify(row));
    //             if (row.aaaId != ' ') {
    //                 let routeData = this.$router.resolve({
    //                     path: '/company/companysnapshot?id3a=' + row.aaaId + '&companyCountry=' + row.countryCode,
    //                 });
    //                 window.open(routeData.href, '_blank');
    //             }
    //         },
    //         //导出
    //         exportCompany() {
    //             console.log('ownership- curPagePower--', this.curPagePower);
    //             if (this.curPagePower[0].power) {
    //                 this.DetailsForm = this.parentTable1;
    //             } else {
    //                 this.message()
    //                 // this.$parent.getOrdersAdd(1).then(res => {
    //                 //     if (res) this.DetailsForm = this.parentTable1;
    //                 // });
    //             }
    //         },

    //         async echartslist() {
    //             const params = {
    //                 // id3a: JSON.parse(sessionStorage.getItem('company')).aaa_id,
    //                 // companyCountry: JSON.parse(sessionStorage.getItem('company')).company_country,
    //                 id3a: this.$route.query.id3a,
    //                 // id3a: JSON.parse(sessionStorage.getItem('company')).aaa_id,
    //                 // companyCountry: JSON.parse(sessionStorage.getItem('company')).company_country,
    //                 companyCountry: this.$route.query.companyCountry,
    //                 charType: 1,
    //             };
    //             const res1 = await getCompanyChart(params);
    //             // console.log(res1);
    //             let ecahrtsdata = JSON.parse(crypto.decrypt(res1));
    //             console.log(this.echartsdate);
    //             if (ecahrtsdata && ecahrtsdata.code) {
    //                 this.loading = false;

    //                 this.echartsdate = ecahrtsdata.data;

    //                 this.setEchartList();
    //                 // data=this.ecahrtsdata
    //                 this.$nextTick(() => {
    //                     this.echarts();
    //                 });
    //             } else {
    //                 this.loading = false;
    //             }

    //         },
    //         setEchartList(echartsdate = this.echartsdate) {

    //             const echartData = echartsdate.subsetList || []; // 股东list

    //             // this.nodesBean = basicInfo.enterprise // 目标公司
    //             // console.log(basicInfo);
    //             // console.log(echartData);
    //             // console.log(this.nodesBean);
    //             const data = {
    //                 name: this.echartsdate.name,
    //                 nameEn: this.echartsdate.nameEn,
    //                 code: this.companyAaaid,
    //                 children: [],
    //             };
    //             // console.log(data);
    //             const childrenList = formatList(echartData, this.companyAaaid, this.companyAaaid);
    //             data.children = childrenList;
    //             // console.log(`data`, data);

    //             if (childrenList && childrenList.length > 0) {
    //                 // console.log(`111`, 111);
    //                 // this.echartsdate=data
    //                 this.getLevel2Children(data, this.companyAaaid);
    //             } else {
    //                 this.echartData = data;
    //                 // this.treeChart(data)
    //             }
    //         },
    //         getLevel2Children(vals = data, aaaid) {
    //             // console.log(`vals`, vals);
    //             const echartData = vals;
    //             console.log(``, echartData);

    //             echartData.children.map((item, index) => {
    //                 if (item.isMax == aaaid) {
    //                     // console.log(`item`, item);
    //                     const echartData = item.subsetList || [];
    //                     const subsetList = formatList(echartData, this.companyAaaid, this.companyAaaid);
    //                     item.subsetList = subsetList;
    //                     // console.log(childrenList);
    //                     // data.children = childrenList
    //                 }
    //             });
    //             this.echartsdate = echartData;
    //             // const list = echartData.children
    //             // const info = {}
    //             // info[vals.aaa_id] = list.map(it => it.aaa_id).filter(it => !!it)
    //             // const maxAaa_id = list.find(it => it.isMax)?.aaa_id
    //             // if (list) {
    //             //   getShareholderList(info).then(res => {
    //             //     const data = res.data
    //             //     if (data) {
    //             //       const childrens = []
    //             //       for (const key in data) {
    //             //         if (Object.hasOwnProperty.call(data, key)) {
    //             //           const element = data[key] || []

    //             //           for (const newId in element) {
    //             //             if (Object.hasOwnProperty.call(element, newId)) {
    //             //               const lastLi = element[newId] || []
    //             //               childrens.push(formatList(lastLi, newId, `${newId}` === `${maxAaa_id}`))
    //             //             }
    //             //           }
    //             //         }
    //             //       }
    //             //       const childrenList = list.map(item => {
    //             //         const newIt = item
    //             //         if (item?.aaa_id) {
    //             //           childrens.map(it => {
    //             //             if (it && it.length > 0) {
    //             //               if (it[0]?.fatherAaa_id === item?.aaa_id) {
    //             //                 newIt.children = it
    //             //               }
    //             //             }
    //             //           })
    //             //         }
    //             //         return newIt
    //             //       })
    //             //       echartData.children = childrenList
    //             //       if (childrenList && childrenList.length > 0) {
    //             //         this.getLevel3Children(echartData)
    //             //       } else {
    //             //         this.echartData = echartData
    //             //         this.treeChart(echartData)
    //             //       }
    //             //     }
    //             //   })
    //             // }
    //         },
    //         changeLevel(newLel) {
    //             if (!this.curPagePower[1].power) {
    //                 this.$parent.getOrdersAdd(19)
    //     }

    //             this.level = newLel;
    //             this.$nextTick(() => {
    //                 this.echarts();
    //             });
    //             // this.treeChart(this.echartData, newLel)
    //         },
    //         echarts() {
    //             const that = this;
    //             let leval = that.level;
    //             const initialTreeDepth = leval;
    //             that.echartsdate = JSON.parse(JSON.stringify(that.echartsdate).replace(/subsetList/g, 'children'));

    //             //  that.echartsdate.map((item,index)=>{

    //             //  })
    //             let data = that.echartsdate;
    //             // console.log(`dataa`, data);
    //             const myChart = this.$echarts.init(this.$refs.trendChart);
    //             myChart.showLoading();

    //             myChart.hideLoading();

    //             const symbol = data => {
    //                 // console.log(`data`, data.code);
    //                 if (`${data.invtypecode}` === '3') {
    //                     // 外国公司
    //                     return this.comIcon3;
    //                 }
    //                 if (`${data.invtypecode}` === '5') {
    //                     // 国营企业
    //                     return this.comIcon5;
    //                 }

    //                 if (`${data.code}` === this.$route.query.id3a) {
    //                     return this.comIcon4;
    //                 }
    //                 if (data.type == '2') {
    //                     // 个人
    //                     return this.comIcon2;
    //                 }
    //                 return this.comIcon1;
    //             };
    //             // console.log(`symbol`,symbol );
    //             const option = {
    //                 tooltip: {
    //                     trigger: 'item',
    //                     triggerOn: 'mousemove',
    //                     fontSize: 12,
    //                     formatter: params => formatterTxtTips(params),
    //                 },
    //                 series: [
    //                     {
    //                         type: 'tree',
    //                         data: [data],
    //                         top: '18%',
    //                         bottom: '14%',
    //                         // roam: true,
    //                         clickable: true,

    //                         layout: 'radial',
    //                         symbol: (value, param) => symbol(param.data),
    //                         symbolSize: 30,
    //                         initialTreeDepth,
    //                         animationDurationUpdate: 750,
    //                         emphasis: {
    //                             focus: 'ancestor',
    //                         },
    //                         orient: 'TB',
    //                         label: {
    //                             fontSize: 9,
    //                             formatter: params => formatterTxt(params),
    //                             rotate: 360,
    //                         },
    //                         leaves: {
    //                             label: {
    //                                 fontSize: 9,
    //                                 formatter: params => formatterTxt(params),
    //                                 rotate: 360,
    //                             },
    //                         },
    //                     },
    //                 ],
    //             };
    //             myChart.setOption(option);

    //             // myChart.resize()
    //         },
    //         handleChange(val) {
    //             this.icon = val;
    //             // this.iconlist=val
    //             // this.iconlist.some(item=>{
    //             //     console.log(item);
    //             // })
    //         },
    //         loginPop() {
    //             this.echartStatus = true;
    //                 this.echarts();

    //             // if (this.curPagePower[1].power) {

    //             // } else {
    //             //     this.$parent.getOrdersAdd(19).then(res => {
    //             //         if (res) {
    //             //             this.echarts();
    //             //         }
    //             //     });
    //             // }
    //         },
    //     },
};
</script>
<style scoped lang="less">
.echartsBtns {
    position: absolute;
    top: 30px;
    left: 30px;
}
.echartsBtns ::v-deep button {
    padding: 4px 15px !important;
    font-size: 15px;
    background-color: transparent;
}
.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}

.Company Snapshot {
}

.Country Data {
}

.title,
.ecahrts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 69px;

    .title-left {
        display: flex;
        font-size: 16px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #022955;
    }
}

.footer {
    margin-top: 20px;
    padding: 20px;

    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
}
.ecahrts-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-left {
        display: flex;
    }
}
.table {
    margin-top: 20px;
}

.trendEchartBox1 {
    width: 980px;
    height: 624px;
}

.ecahrtstu {
    position: relative;
    display: flex;
    justify-content: center;
    width: 980px;

    background: #ffffff;
}
.table_box /deep/ .el-table th > .cell:first-child {
    padding-left: 20px;
}
.table_box /deep/ .el-table .cell,
.el-table--border td:first-child .cell,
.table_box /deep/ .el-table--border th:first-child .cell {
    padding-left: 19px;
}

.tbl_search_box {
    /* position: absolute;
  left: 0; */
    margin-right: 10px;
}
.title_txt-right {
    display: flex;
}

.tbl_search {
    position: relative;
    width: 290px;
    height: 40px;
    z-index: 9;
}
.tbl_search /deep/ .el-input .el-input__inner {
    width: 100%;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(46, 118, 167, 0.4);
    border-radius: 8px;
    border: 1px solid #1290c9;
    padding: 0 40px 0 20px;
}
.tbl_search .el-input {
    height: 100%;
}
.tbl_search /deep/ .el-input .el-input__suffix {
    right: 40px;
}
.tbl_search img {
    position: absolute;
    width: 18px;
    right: 20px;
    top: 12px;
}
.title {
    margin-bottom: 10px;
}
.title_txt {
    font-size: 16px;
    font-family: 'Arial Bold';
}
.title_txt img {
    width: 20px;
    margin-right: 8px;
}

.table_box /deep/ .el-table .ascending .sort-caret.ascending {
    border-bottom-color: #1290c9;
}
.table_box /deep/ .el-table .sort-caret.descending {
    border-top-color: #c0c4cc;
}
.table_box /deep/ .el-table .sort-caret.ascending {
    border-bottom-color: #c0c4cc;
}
.table_box /deep/ .el-table .descending .sort-caret.descending {
    border-top-color: #1290c9;
}
.column-handle {
    display: inline-block;
}
@media (max-width: 821px) {
        .phone_table_box_item{
     display: flex;
     /* justify-content: center; */
     /* align-items: center; */
     padding: 20px 0;
     border-bottom:1px solid #e0dce5;
    }
   .table_box .phone_table_box_item img {
 
     width: 28px;
     height: 17px;
     flex: none;
     margin-right: 4px;
     box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 20%);
    }
    .phone_table_box_item_right{
      font-size: 14px;
      margin-left: 20px;
         width: 85%;
         
    }
    .phone_table_box_item_right h1{
        font-size: 14px;
         font-weight: normal;
             
   
    }
     .phone_table_box_item_right h2{
        font-size: 12px;
        color: #656373;
         font-weight: normal;
         line-height: 22px;
       
       
    }
    .title{
        margin-top: 20px !important;
    }
    .tbl_search{
        width: 200px !important;
    }
    .phone_table_box_item_right .line_1 {
    white-space: nowrap !important;
}
    }
</style>